import { createI18n } from 'vue-i18n'
import en from '../locales/en.json'
import zh_CN from '../locales/zh_CN.json'
import zh_TW from '../locales/zh_TW.json'
import fr from '../locales/fr.json'
import de from '../locales/de.json'
import es from '../locales/es.json'
import ja from '../locales/ja.json'
import ko from '../locales/ko.json'
import it from '../locales/it.json'
import el from '../locales/el.json'
import th from '../locales/th.json'
const messages = {
  en,
  zh_CN,
  zh_TW,
  fr, de, es, ja, ko, it, el, th
}

const i18n = createI18n({
  locale: 'en', // set default locale
  fallbackLocale: 'en',
  messages,
  legacy: false,
})

export default i18n