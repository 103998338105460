<template>
    <div id="app">
      <router-view :key="componentKey"></router-view>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  import { useUserStore } from './stores/user'
  import { onMounted, reactive, computed, watch, watchEffect, provide, ref, onErrorCaptured } from 'vue'
  // import { io } from 'socket.io-client'
  // import {file_one_line_fs_desc, file_one_line_ms_desc} from './constants_gptagent.js';
  import { getTranslatedConstants } from './constants_gptagent.js';
  
  import axios from 'axios';
  import {loadScript, gen_random_string} from './loadScript.js';
  import { useRouter, useRoute } from 'vue-router'
  import { initializeSocket } from './socketService.js'
  export default {
    name: 'App',
    setup() {
      // const socket = io({reconnectionAttempts: 30, reconnectionDelay:3000, reconnectionDelayMax: 15000,origins:"*"})
      // const socket = getSocket();
      // onErrorCaptured((error, instance, info) => {
      //   console.error('Global Error Captured:');
      //   console.error('Error:', error);
      //   console.error('Component:', instance);
      //   console.error('Info:', info);
      //   console.error('Stack trace:', error.stack);
      //   return false;
      // });
      const { t, locale } = useI18n();
      
      const userStore = useUserStore();
      const default_model_select_options = [
          { text: "ChatGPT (Auto)", value: "chatgpt_model" },
          { text: "GPT-4o (Omni)", value: "gpt4o_model"},
          { text: "GPT-4 Turbo", value: "gpt4_model" },
          { text: "Claude-3.5 (Sonnet)", value: "claude35_sonnet"},
          { text: "Claude-3 (Haiku)", value: "claude3_haiku"},
          { text: "GPT-4 Turbo", value: "gpt4_model"},
      ];
      function make_dropdown(in_or_out) {
        let locale = gstate?.user_info_json?.locale || "en";
        let prefix="";
        let output = [];
        if (["google","deepl","gpttranslate","genexercise"].includes(gstate?.function_select)) {
            if (gstate.src_lang != 'nil'){
                if (gstate?.function_select==='google') {
                    prefix = 'gt_'}
                else if (gstate?.function_select==="deepl") {
                    prefix="dl_"
                }
                else if (gstate?.function_select==="genexercise") {
                    prefix="dl_"
                    //not very ideal, but a cheap way to generate auto & other languages
                }
                else if (["gpttranslate"].includes(gstate?.function_select)) {
                    prefix="gpt_"
                }
                else if (gstate?.function_select==='cc') {
                    prefix = 'cc_'
                }
                else{ prefix = 'support_'
                    }
                let df_mask = prefix + in_or_out // gt_in, support_out etc
                let smalldf = gstate.languages_df.filter((entry)=> entry[df_mask] ===1) //JSON.parse(gstate.languages_df).filter((entry)=> entry[df_mask] ===1) 
                //filter out languages that have no gt_in, support_out etc
                // console.log("prefix",prefix,"df_mask",df_mask, "smalldf",smalldf,"gstate.languages_df",gstate.languages_df)
                if (in_or_out == 'out'){
                    smalldf = smalldf.filter((entry)=> entry["abbr"] !=="zh-both");
                    let smalldftext = JSON.stringify(smalldf)
                    let filters = [entry => entry['abbr'] !=gstate.src_lang, entry=> entry['abbr'] !='nil']
                    smalldf=JSON.parse(smalldftext).filter((entry)=>filters.every(fn=>fn(entry)));
                    if (gstate.src_lang && gstate.src_lang.includes('zh')) {
                        smalldftext = JSON.stringify(smalldf)
                        smalldf=JSON.parse(smalldftext).filter((entry)=>entry['abbr'] !='zh-both')
                    }
                }
                locale = locale + '_name';
                let obj = null;
                for (let index in smalldf) {
                    obj = smalldf[index];
                    // output = output + `<option value="${obj['abbr']}">${obj[locale]}</option>`;
                    output.push({"value":obj['abbr'],"text":obj[locale]})
                }
            }
            else{
                // output = '<option value="nil">{{$t("No Translation")}}</option>'
                output.push({"value":"nil","text":t("No Translation")})
            }
          }
          return output;
      };
      const gstate = reactive({
        counter: 0,
        socket_room: 'A1B2C3D4E5F6G7H8',
        disable_fs_n_control: false,
        ai_mode:"text", //"file"
        expand_right_panel: true,
        mounted_components : [],
        used_token: 0,
        uf: {},
        func: {},
        fsvstate: {},
        mlstate: {
          "global_chat_id": -1,
          "global_filekey": -1,
          "show":false,
          "sharelink_store": "",
        },
        ustate: {
          "pond_version": 1.00,
          "umode":"gptassist", //file2file
        },
        ostate: {},
        fstate: {
          show_all: true,
          fmode: 'pond', // 'pond' use pond for uploadpond, 'main' use main for filemanager
        },
        mapping: {}, //store outfile's ufkey -> srcfile's ufkey mapping; remember to remove mapping on done or failed, to prevent cross contamination
        os_color: false, //turn on color for option selectors?
        function_select: "deepl",
        model_select: "deepl",
        ocr: "auto",
        src_selector_options: computed(()=> {
                                    let output = [];
                                    if (gstate && gstate.user_info_json && gstate.languages_df) {
                                        output =  make_dropdown("in")
                                    }
                                    return output
                                }),
        trg_selector_options: computed(()=> {
                                    let output = [];
                                    if (gstate && gstate.user_info_json && gstate.languages_df) {
                                        output= make_dropdown("out")
                                    }
                                    return output
                                }),
        src_lang: "auto",
        trg_lang: "en",
        rephrase_imperfection: 1,
        rephrase_vocab:"default",
        rephrase_tone:"business",
        rephrase_limit:null,
        gx_prompt: "",
        gx_trg_lang: "auto",
        gx_difficulty:"0",
        gx_num_questions : "10",
        gx_num_choices :"auto",
        custrw_bg_in: '',
        custrw_task_in: '',
        custex_bg_in: '',
        custex_task_in: '',
        summary_user_has_set: 'pct',
        summary_pct: 25,
        summary_word: 400,
        models:computed(() => {
            let models = []; //represent use_model in backend
            if (gstate.function_select === "compare") {
                models = ["chatgpt_model","gpt4_model",];
            } else if (gstate.function_select === "compare_opus_gpt4") {
                models = ["claude3_opus","gpt4_model"];
            }
            else if (["google","deepl","claude35_sonnet","claude3_opus","claude3_haiku"].includes(gstate.model_select) || gstate.model_select.includes("gpt4") || gstate.model_select.includes("chatgpt")) {
                models = [gstate.model_select];
            }
            else {
                // console.log("computed property of models should not be empty or everything would fail!")
                // models = [state.function_select]
                if (gstate.function_select) console.error(`Models not set for fs=${gstate.function_select} & ms=${gstate.model_select}!`);
            }
            if (models.length === 0) console.error("models should not be empty!")
            return models
        }),
        model_select_options: computed(()=> {
          let model_select_options = default_model_select_options;
          let abd = gstate?.user_info_json?.gptagent_dict;
          if(abd) {
              if(gstate.function_select === "ngpt4") {
                  // model_select_options = default_model_select_options.filter((option) => option.value.includes("gpt4"));
                  model_select_options = [{text:"GPT-4 Turbo",value:"gpt4_model"}];
              } else if (gstate.function_select === "ngpt4o") {
                  model_select_options = [{text:"GPT-4o (Omni)",value:"gpt4o_model"}];
              } else if (gstate.function_select === "nsonnet") {
                  model_select_options = [{text:"Claude-3.5 (Sonnet)",value:"claude35_sonnet"}];
              } else if (gstate.function_select === "nopus") {
                  model_select_options = [{text:"Claude-3 (Opus)",value:"claude3_opus"}];
              } else if (gstate.function_select === "nhaiku") {
                  model_select_options = [{text:"Claude-3 (Haiku)",value:"claude3_haiku"}];
              }
              else if (gstate.function_select === "nchatgpt") {
                  model_select_options = default_model_select_options.filter((option) => option.value.includes("chatgpt"));
              }
              else if (gstate.function_select === "compare") {
                  model_select_options = [{text:"ChatGPT+GPT4",value:"compare"}];
              }
              else if (gstate.function_select === "compare_opus_gpt4") {
                  model_select_options = [{text:"Claude-3 (Opus)+GPT4",value:"compare"}];
              }
              else if (gstate.function_select === "google") {
                  model_select_options = [{text:"Google Translate",value:"google"}];
              }
              else if (gstate.function_select === "deepl") {
                  model_select_options = [{text:"DeepL Translate",value:"deepl"}];
              }
              else {
                  model_select_options = default_model_select_options;
              };
              if (!abd.hasOwnProperty(gstate.function_select)) {
                  console.error("Invalid function_select/Forgot to implement excel entry for",gstate.function_select,abd)
              } else {
                  if (abd[gstate.function_select]["preferred_models"].length !== 0) {
                      model_select_options = abd[gstate.function_select]["preferred_models"].map(modelKey => ({
                              text: model_value_to_name[modelKey],
                              value: modelKey
                          }));
                      if (gstate.ai_mode === "file") {
                          console.log("Expunging chatgpt from model_select_options because tc bad");
                          model_select_options = model_select_options.filter((option) => option.value!=="chatgpt_model");
                          // model_select_options = model_select_options.filter((option) => !option.value.includes("claude"));
                      }
                      if (gstate.user_info_json && !gstate.user_info_json.is_paying_user) {
                          model_select_options = model_select_options.filter((option) => !pay_only_use_models.includes(option.value));
                      }
                  } 
                  let oldModelSelect = gstate.model_select;
                  let is_valid_model_selection = model_select_options.some(option => option.value === oldModelSelect);
                  if (!is_valid_model_selection) {
                      console.log("Invalid model select options, reverting to default. gstate.model_select was", gstate.model_select)
                      gstate.model_select = model_select_options[0].value;
                  };

              }
          }
          return model_select_options;
      }),
      })
      gstate.func["getMimeType"] = function getMimeType(filename) {
        const mimeTypes = {
          'pdf': 'application/pdf',
          'jpg': 'image/jpeg',
          'jpeg': 'image/jpeg',
          'png': 'image/png',
          'gif': 'image/gif',
          'txt': 'text/plain',
          'html': 'text/html',
          'css': 'text/css',
          'js': 'application/javascript',
        };
        const fileExtension = filename.split('.').pop().toLowerCase();
        return mimeTypes[fileExtension] || 'application/octet-stream';
      }
      gstate.func["show_toast"] = function show_toast(message) {
          var toastEl = document.getElementById('liveToast');
          var toast = new bootstrap.Toast(toastEl);
          toastEl.querySelector('.toast-body').textContent = message;
          toast.show();
          setTimeout(function(){ toast.hide(); }, 4500);
      }

      gstate.func["count_word"] = function count_word(input) {
          function isCjk(char) {
              const cjkRanges = [
                  [0x4E00, 0x9FFF],  // CJK Unified Ideographs
                  [0x3400, 0x4DBF],  // CJK Unified Ideographs Extension A
                  [0x20000, 0x2EBEF],  // CJK Unified Ideographs Extensions B-F
                  [0x2F800, 0x2FA1F],  // CJK Compatibility Ideographs Supplement
                  [0xAC00, 0xD7AF],  // Hangul Syllables
                  [0x1100, 0x11FF],  // Hangul Jamo
                  [0x3130, 0x318F],  // Hangul Compatibility Jamo
                  [0xA960, 0xA97F],  // Hangul Jamo Extended-A
                  [0xD7B0, 0xD7FF],  // Hangul Jamo Extended-B
                  [0x3040, 0x30FF],  // Hiragana and Katakana
                  [0x31F0, 0x31FF],  // Katakana Phonetic Extensions
                  [0xFF66, 0xFF9F],  // Halfwidth Katakana
                  [0x1B000, 0x1B16F],  // Kana Extended and Small Kana Extension
              ];

              const code = char.charCodeAt(0);
              return cjkRanges.some(([start, end]) => code >= start && code <= end);
          }

          if (input === "") return 0;

          let wordCount = 0;
          let nonCjkBuffer = "";

          for (let char of input) {
              if (isCjk(char)) {
                  // Process any accumulated non-CJK characters
                  if (nonCjkBuffer.trim()) {
                      wordCount += nonCjkBuffer.trim().split(/\s+/).length;
                      nonCjkBuffer = "";
                  }
                  wordCount++; // Count each CJK character as a word
              } else {
                  nonCjkBuffer += char;
              }
          }

          // Process any remaining non-CJK characters
          if (nonCjkBuffer.trim()) {
              wordCount += nonCjkBuffer.trim().split(/\s+/).length;
          }

          return wordCount;
      }
      gstate.func["draw_attention"] = function draw_attention(element, message) {
          if (!element) {
            console.log("element not found when attempting to draw attention", element);
            return;
          }
          element.scrollIntoView({behavior: "smooth"});
          element.focus();

          element.classList.add('alert-button');
          const textbox = document.createElement('label');
          textbox.classList.add("form-check-label", "alert-button") //.add('textboxtwo', 'text-dark', 'bg-white');
          textbox.innerText = message;
          // element.appendChild(textbox);
          element.parentElement.insertBefore(textbox, element);
          // textbox.style.display = 'block';
          setTimeout(() => {
              element.classList.remove('alert-button');
              if (textbox) {
                  // textbox.style.display = 'none'; // hide the textbox
                  textbox.remove();
              }
          }, 5000);
          setTimeout(() => {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 10);
      }
      const router = useRouter()
      const route = useRoute()
      const componentKey = ref(0)
      const navigateTo = (path) => {
        const targetRoute = router.resolve(path)
        // console.log("targetRoute", targetRoute)
        // console.log("targetcomponent", targetRoute.matched[0].components.default.name, "source", route.matched[0].components.default.name, targetRoute.matched[0].components.default.name===route.matched[0].components.default.name)
        if (targetRoute.meta?.login_required === true && gstate?.user_info_json?.loggedin !== true) {
          console.log("Attempting to access login required link and server side check not triggered")
          window.location.href = path; //use this method to properly redirect after login
        }
        if (targetRoute.meta?.parentComponentName === null || route.meta?.parentComponentName === null) {
          console.log("Forgot to set parentComponentName", targetRoute, route)
        }
        // if (targetRoute.meta?.parentComponentName === "Askai" && gstate?.mounted_components?.includes("Askai")) {
        if (["Askai","FileProc"].includes(route.meta?.parentComponentName)) { // there is a bug in Askai component that prevents me from navigating away - no time to fix it yet
          window.location.href = path; // must reload completely to allow get_dict_from_list to work correctly. sometimes it seems there are two state.model_msg? one is empty?
        }
        else if (["Askai","FileProc"].includes(targetRoute.meta?.parentComponentName)) { // there is a bug in Askai component that prevents me from navigating away - no time to fix it yet
          window.location.href = path; // must reload completely to allow get_dict_from_list to work correctly. sometimes it seems there are two state.model_msg? one is empty?
        }
        if (path === route.path || (targetRoute.meta?.parentComponentName !== null && route.meta?.parentComponentName && targetRoute.meta?.parentComponentName)) { //targetRoute.matched[0].components.default === route.matched[0].components.default
          componentKey.value++
          // console.log("adding component key")
          router.push(path)
        } else {
          router.push(path)
        }
      }
      
      const no_setting_fs = ["grammar"] //function_selects that do not have settings such as {fs}_selector
      const file_always_combine_fs = ["genexercise"] //fs that always call start_combine
      const file_chat_linked_fs = ["genexercise"]
      const vision_model_select = ["gpt4_model","gpt4o_model", "claude3_haiku","claude3_opus","claude3_sonnet","claude35_sonnet"]
      const pay_only_use_models = ["claude3_opus"]
      const track_changes_function = ["grammar","rephrase"];
      const switch_fs = ["deepl", "google", "gpttranslate"]; //function_selects that require switching
      const model_value_to_name = {
          "gpt4_model": "GPT-4 Turbo",
          "gpt4o_model": "GPT-4o Omni",
          "chatgpt_model": "ChatGPT (Auto)",
          "claude35_sonnet": "Claude-3.5 (Sonnet)",
          "claude3_opus": "Claude-3 (Opus)",
          "claude3_haiku": "Claude-3 (Haiku)",
      }
      
      const model_context = computed (()=> {
          let max_token = 8123;
          switch(gstate.model_select) {
              case "gpt4_model":
                  max_token = 128000;
                  break;
              case "gpt4o_model":
                  max_token = 128000;
                  break;
              case "chatgpt_model":
                  max_token = 16000;
                  break;
              case "claude35_sonnet":
              case "claude3_sonnet":
              case "claude3_haiku":
              case "claude3_opus":
                  max_token = 200000;
                  break;
              default:
                  max_token = 7456;
          }
          return max_token;
      });
      const model_output = computed (()=> {
          let max_token = 4096;
          switch(gstate.model_select) {
              default:
                  max_token = 4096;
          }
          return max_token;
      });
      const remaining_context_length = computed (() => {
          let max_token_cap_on_user = 9999999;
          if (gstate.user_info_json && gstate.user_info_json.limit_context) {
              max_token_cap_on_user = gstate.user_info_json.limit_context;
          }
          let remaining = Math.min(max_token_cap_on_user, model_context.value) - gstate.used_token;
          return remaining;
      });
      const remaining_output = computed (() => {
          let remaining = Math.min(remaining_context_length.value, model_output.value);
          console.log("remaining_output_length",remaining, "remaining_context_length",remaining_context_length.value, "model_output",model_output.value);
          return remaining;
      })
      const cc = { //cannot store computed properties to gstate then provide it
        "remaining_context_length": remaining_context_length,
        "remaining_output": remaining_output,
        "model_context": model_context,
        "model_output": model_output,
        "model_value_to_name": model_value_to_name,
      }
      async function saveUserSetting(kvPairs) {
        // "Input look like this : const settingsToSave = [
        //   ['show_advanced', true],
        //   ['show_tutorial', false],
        // ];"
        try {
          const response = await fetch('/_save_user_setting', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ kv_pair: kvPairs }),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          
          if (data.status === 'success') {
            if (!(gstate.hasOwnProperty('user_setting')) || gstate.user_setting === null) {
              gstate.user_setting = {};
            } 
            kvPairs.forEach(([key, value1]) => {
              gstate.user_setting[key] = value1;
            });
            console.log('User settings saved successfully');
          } else {
            console.error('Failed to save user settings:', data.msg);
          }

          return data;
        } catch (error) {
          console.error('Error saving user settings:', error);
          return { status: 'failed', msg: 'An error occurred while saving user settings' };
        }
      }
      function split_filename_and_ext(filename) {
        const lastDotIndex = filename.lastIndexOf('.');
        let fileName, fileExt;
        if (lastDotIndex === -1) {
            fileName = filename;
            fileExt = 'unknown_ext';
        } else {
            fileName = filename.slice(0, lastDotIndex);
            fileExt = filename.slice(lastDotIndex + 1).toLowerCase();
        }

        return [fileName, fileExt];
      }

      function dest2text(label, labeldict) {
        let ext_to_description = {
          "pdf": t("PDF"),
          "docx": t("Word"),
          "pptx": t("PowerPoint"),
          "txt": t("Text"),
          "htm": t("HTML"),
          "html": t("HTML"),
          "zip": t("ZIP"),
          "xlsx": t("Excel"),
        }
        // front, ext = split_filename_and_ext(dest.filename);
        const [front, ext] = split_filename_and_ext(labeldict.filename);
        let prefix = t('Download ');
        let midfix = "";
        let afterfix = ""
        let extfix = "File";
        if (ext_to_description.hasOwnProperty(ext)) {
          extfix = ext_to_description[ext];
        }
        // if (["zip","txt"].includes(label)) {
        //   extfix = ext_to_description[label];
        // }
        if (label.includes("compare")) {
          afterfix = t("(Track Changes)");
        } else if (["merge","merged"].includes(label)) {
          afterfix = t("(Merged)");
        }
        
        if (midfix !== "" ) midfix = " " + midfix + " ";
        if (afterfix !== "" ) afterfix = " " + afterfix;
        
        let out = prefix + midfix + extfix + afterfix;
        return out;
      }
      function setCookie(cname, cvalue, exdays=99999) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/" + ";SameSite=Lax";
      }
    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    function reading_share() {
        return (window.location.href.includes("/x/") || window.location.href.includes("/innerx/"))
    }
    function request_to_brand() {
      let outjson = {};
      if (window.location.href.includes("fastlaw.cc"))
          {outjson = {"email":"support@fastlaw.cc", "domain_name":"fastlaw.cc","brand_name":'{{_("Fast Law")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://fastlaw.cc . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@fastlaw.cc)")}}'}}
      else if (window.location.href.includes("kuaifa.ai"))
          {outjson = {"email":"support@kuaifa.ai", "domain_name":"kuaifa.ai","brand_name":'{{_("Kuai Fa")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://kuaifa.ai . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@kuaifa.ai)")}}'}}
      else if (window.location.href.includes("gptbowl"))
          {outjson = {"email":"support@gptbowl.com", "domain_name":"gptbowl.com","brand_name":'{{_("GPT Bowl")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://www.gptbowl.com . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@gptbowl.com)")}}'}}
      else
      {outjson = {"email":"support@gptbowl.com", "domain_name":"gptbowl.com","brand_name":'{{_("GPT Bowl")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://www.gptbowl.com . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@gptbowl.com)")}}'}}
      return outjson
    }
    function getUuidFromUrl() {
        const url = new URL(window.location.href);
        const parts = url.pathname.split('/').filter(part => part !== '');
        if (parts.length >= 2 && (parts[0] === 'x' || parts[0] === 'y')) {
            return parts[1];
        }
        // console.error("Error getting uuid",parts)
        return null;
    }
      gstate.domain_json = request_to_brand();
      
      watch(() => gstate.src_lang, (newValue) => {
            if (["google","deepl","gpttranslate","genexercise"].includes(gstate?.function_select)) {
                let old_src_select = gstate.src_lang;
                let is_valid_src_selection = gstate.src_selector_options.some(option => option.value === old_src_select);
                if (!is_valid_src_selection) {
                    gstate.src_lang = gstate.src_selector_options[0].value;
                };
                let old_trg_select = gstate.trg_lang;
                let is_valid_trg_selection = gstate.trg_selector_options.some(option => option.value === old_trg_select);
                if (!is_valid_trg_selection) {
                    gstate.trg_lang = gstate.trg_selector_options[0].value;
                }
            };
        });
      // const preloadComponents = (path) => {
      //   if (path.startsWith('/o') || path === '/overview') {
      //     import('./components/Overview.vue');
      //   }
      //   else if (['/t', '/v', '/innerx'].some(p => path.startsWith(p))) {
      //     console.log("Starting Askai preloading");
      //     import('./components/Askai.vue');
      //     console.log("Finished Askai preloading");
      //   }
      //   else if (path.startsWith('/f')) {
      //     import('./components/FileProc.vue');
      //   }
      //   else if (path.startsWith('/x/')) {
      //     import('./components/Askai.vue');
      //   }
      //   else if (path.startsWith('/y/')) {
      //     import('./components/FileSharedViewer.vue');
      //   }
      //   else if (path.startsWith('/addcredit')) {
      //     import('./components/Addcredit.vue');
      //   }
      //   else if (path === '/fileviewer') {
      //     import('./components/FileViewer.vue');
      //   }
      // };

      onMounted(async () => {
        console.log("Mounting App")
        gstate.socket_room=gen_random_string(16);

        function vtf (){
          const url = new URL(window.location.href);
          const parts = url.pathname.split('/').filter(part => part !== '');
          let fs = '';
          let ms = '';
          let vtf = '';
          if (parts.length >= 1) vtf = parts[0];
          if (parts.length >= 2) fs = parts[1];
          if (parts.length >= 3) ms = parts[2];
          fs = fs.split("#")[0];
          fs = fs.split("?")[0];

          if (fs !== '' && fs !== null && !["x", "y", "innerx"].includes(vtf)) {
              setTimeout(() => {
                console.log("changing fs",fs)
                  gstate.function_select = fs;
                  if (ms !== '' && ms !== null) {
                      gstate.model_select = ms;
                  }
              }, 0);
          }
        }

        gstate.func["vtf"] = vtf;
        vtf();

        function fetch_user_info_n_share_uuid () {
          let share_uuid = getUuidFromUrl(); //
          userStore.fetchUserInfo({share_uuid: share_uuid}).then(async () => {
            console.log("User info fetched");
            if (userStore.responseData) {
              gstate.user_info_json = userStore.responseData.user_info_json;
              initializeSocket(gstate);
              
              gstate.languages_df = userStore.responseData.languages_df;
              gstate.languages_df = JSON.parse(gstate.languages_df);
              gstate.user_setting = userStore.responseData.user_setting;
              if (!gstate.user_setting) {
                gstate.user_setting = {};
              }
              if (!gstate.user_setting.hasOwnProperty("show_advanced")) {
                gstate.user_setting.show_advanced = false;
              }
              if (!gstate.user_setting.hasOwnProperty("show_advanced_pond")) {
                gstate.user_setting.show_advanced_pond = false;
              }
              if (!gstate.user_setting.hasOwnProperty("show_system")) {
                gstate.user_setting.show_system = false;
              }
              if (!gstate.user_setting.hasOwnProperty("display_dashed")) {
                gstate.user_setting.display_dashed = true;
              }
              locale.value = userStore.responseData.locale; //this is how frontend locale is set
              gstate.counter +=1;
              if (userStore.responseData.hasOwnProperty("shared_chat_json")) {
                gstate.shared_chat_json = userStore.responseData.shared_chat_json;
              }
              gstate.user_info_json["gptagent_dict"] = JSON.parse(gstate.user_info_json["gptagent_dict"]);
            }
            if (reading_share()) {
                
                const uuid = getUuidFromUrl();
                try {
                    const response = await axios.get(`/_chatshare/${uuid}`);
                    console.log('Share data:', response.data);
                    gstate.chatshare = response.data;
                } catch (error) {
                    console.error('Error fetching share data:', error);
                    // Handle the error (e.g., show an error message to the user)
                }
            }
          });
        }
        gstate.func["fetch_user_info_n_share_uuid"] = fetch_user_info_n_share_uuid;
        gstate.func["split_filename_and_ext"] = split_filename_and_ext;
        fetch_user_info_n_share_uuid(); //need to run again for any page that is prone to have share_uuid

        //REMEMBER App.vue run only once and does not get triggered on navigateTo, only gets triggered if href.location change
        watch(() => gstate.function_select, (newValue) => {
            let abd = gstate?.user_info_json?.gptagent_dict;
            if (abd) {
              const preferredModel = abd[newValue]["preferred_models"].find(modelKey => 
                gstate.model_select_options.some(option => option.value === modelKey)
              );
              
              // If a valid preferred model is found, update model_select
              if (preferredModel) {
                  console.log("setting preferred")
                  gstate.model_select = preferredModel;
              }
            }
        });
        // const scriptUrls = [
        //   // "/static/assets/js/headroom.min.js",
        //   // "/static/assets/js/pixel.js",
        //   // "/static/assets/vendor/bootstrap/dist/js/bootstrap.min.js",
        //   // "/static/assets/js/corejsbundle332.js",
        // ];
        // const promises = scriptUrls.map(url => loadScript(url));
        // try {
        //     await Promise.all(promises);
        //     console.log('All main scripts loaded successfully');
        // } catch (error) {
        //     console.error('Failed to load external assets:', error);
        // }
        console.log("Finishing Mounting App")
        // preloadComponents(route.path);
      })
      const configs = {
        "spec_attributes": ["ocr","src_lang","trg_lang","function_select","model_select","rephrase_imperfection", "rephrase_vocab", "rephrase_tone", "rephrase_limit", "gx_prompt", "gx_trg_lang", "gx_difficulty", "gx_num_questions", "gx_num_choices", "custrw_bg_in", "custrw_task_in", "custex_bg_in", "custex_task_in", "summary_user_has_set", "summary_pct", "summary_word"],
        list_of_fs_do_track_changes : ["rephrase", "grammar", "compare"],
        list_of_fs_require_multiline2text : ["summarize"],
      }
      provide('getTranslatedConstants',getTranslatedConstants)
      provide('gstate', gstate)
      provide('t', t)
      provide('locale', locale)
      provide('configs', configs)
      // provide('socket', socket)
      provide('setCookie', setCookie)
      provide('getCookie', getCookie)
      provide('reading_share', reading_share)
      provide('getUuidFromUrl', getUuidFromUrl)
      provide('saveUserSetting', saveUserSetting)
      provide('navigateTo', navigateTo)
      provide('dest2text', dest2text)
      provide('cc', cc)
      return { t, locale, userStore, gstate, setCookie, getCookie,saveUserSetting, navigateTo, componentKey} //, socket
    }
  }
  </script>
<style>
.alert-button {
    animation: breathing 3s infinite;
    border-color: crimson;
    box-shadow: 0 0 5px crimson;
}
.pb-lg-max {
  padding-bottom: 40rem !important; 
}
</style>