// stores/user.js
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    responseData: null,
  }),

  actions: {
    async fetchUserInfo(input_data) {
      try {
        const response = await fetch('/_configs', {
          method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(input_data)
        });
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();
        this.responseData = data;
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    }
  }
});