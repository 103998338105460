export function loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(script);
      script.onerror = () => reject(new Error(`Script load error: ${src}`));
      document.head.appendChild(script);
    });
  }

// export function loadPriorityScripts() {

//   console.log("Loading Priority Scripts")
//   const scripts = [
//     '/static/assets/vendor/bootstrap/dist/js/bootstrap.min.js',
//     '/static/assets/js/corejsbundle332.js',
//     "/static/assets/js/pixel.js",
//     // "/static/assets/js/pixel_custom.js",
//   ];

//   return Promise.all(scripts.map(src => {
//     return new Promise((resolve, reject) => {
//       if (document.querySelector(`script[src="${src}"]`)) {
//         resolve();
//       } else {
//         const script = document.createElement('script');
//         script.src = src;
//         script.async = false;
//         script.onload = resolve;
//         script.onerror = reject;
//         document.head.appendChild(script);
//       }
//     });
//   }));
// }

export async function loadExternalAssets() {
  console.log("Called load external assets!")
  await loadScript('/static/assets/js/jquery.min.js')
  await loadScript('/static/assets/js/createjs.min.js') //needed for video, must load before HTML5 Canvas.js
  const scriptUrls = [
      // '/static/assets/js/katex.min.js',
      '/static/assets/js/intro.js',
      '/static/assets/js/pdf.min.js',
      // 'https://cdn.jsdelivr.net/npm/jszip@3.7.1/dist/jszip.min.js',
      '/static/assets/js/docx-preview.js',
      '/static/assets/js/dom-to-image.js',
      // '/static/assets/js/jquery.min.js',
      // '/static/assets/js/jquery.ui.touch-punch.min.js', //causes problem
      '/static/assets/js/jquery-ui.min.js',
      // '/static/assets/video/rearrangencombine_HTML5 Canvas.js',
      '/static/assets/js/prism.js',
      '/static/assets/js/purify.min.js',
      '/static/assets/js/customdiffmatchpatch.js',
      // '/static/assets/js/dropzone6.js', // was using this!
  ];
  const promises = scriptUrls.map(url => loadScript(url));
  try {
      await Promise.all(promises);
      console.log('All scripts for aa loaded successfully');
  } catch (error) {
      console.error('Failed to load external assets:', error);
  }
}

export async function renderCore() {
  console.log("Loading renderCore")
    window.texme = { renderOnLoad: false,
      markdownURL: '/static/assets/js/marked.min.js',
      MathJaxURL: '/static/assets/js/tex-mml-chtml.js'

  };
  const scriptUrls = [
      '/static/assets/js/prism.js',
      '/static/assets/js/purify.min.js',
      '/static/assets/js/customdiffmatchpatch.js',
      '/static/assets/js/texme.js'
  ];
  const promises = scriptUrls.map(url => loadScript(url));
  try {
      await Promise.all(promises);
  } catch (error) {
      console.error('Error when attempting to load renderCore:', error);
  }
}

export function gen_random_string(length=16) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
  }
  return randomString;
}