<template>
    <header class="header-global">
    <nav id="navbar-main" aria-label="Primary navigation" class="navbar navbar-main navbar-expand-lg navbar-theme-primary headroom navbar-dark text-dark" style="padding-right: 0.2rem;">
        <div class="container position-relative">
            <a class="navbar-brand me-lg-5" @click.prevent='navigateTo("/index")'>
                <img class="navbar-brand-dark" src="/static/assets/img/brand/dark120x120.png" alt="Logo light">
                <img class="navbar-brand-light" src="/static/assets/img/brand/dark120x120.png" alt="Logo dark">
            </a>
            <div class="navbar-collapse collapse me-auto" id="navbar_global">
                <div class="navbar-collapse-header">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <a @click.prevent='navigateTo("/index")'>
                                <img src="/static/assets/img/brand/dark120x120.png" alt="GPT Bowl">
                            </a>
                        </div>
                        <div class="col-6 collapse-close">
                            <a href="#navbar_global" id="closehamburger" class="fas fa-times" data-bs-toggle="collapse" data-bs-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" title="close" aria-label="Toggle navigation"></a>
                        </div>
                    </div>
                </div>
                <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
                    <li class="nav-item dropdown">
                        <a @click.prevent='navigateTo("/overview")' class="nav-link" id="chatgpt">
                            {{$t('All Features')}}
                        </a>
                    </li>
                    <li class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" id="askaiDropdown" aria-expanded="false">
                            {{$t("Ask AI")}}
                            <span class="fas fa-angle-down nav-link-arrow ms-1"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg" aria-labelledby="askaiDropdown">
                            <div class="col-auto px-0">
                                <div class="list-group list-group-flush">
                                    <a @click.prevent='navigateTo("/t/nsonnet")'
                                    class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">                                           
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('Ask Claude 3.5 Sonnet (Best)')  }}</span>                                            
                                        </div>
                                    </a>
                                    <a @click.prevent='navigateTo("/t/ngpt4o")' class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">                                           
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('Ask GPT-4o Omni')  }}</span>                                            
                                        </div>
                                    </a>
                                    <a @click.prevent='navigateTo("/t/gpttranslate")' class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">                                           
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('ChatGPT Translate')  }}</span>                                            
                                        </div>
                                    </a>
                                    <a @click.prevent='navigateTo("/t/rephrase")' class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">                                           
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('Rephrase Text')  }}</span>                                            
                                        </div>
                                    </a>
                                    <a @click.prevent='navigateTo("/overview?e=text")' class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">                                           
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('See More')  }}</span>                                            
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li> 
                    <li class="nav-item dropdown">
                        <a @click.prevent='navigateTo("/overview?c=file")' class="nav-link dropdown-toggle" data-bs-toggle="dropdown" id="fileDropdown" aria-expanded="false">
                            {{$t('AI File Processing')}}
                            <span class="fas fa-angle-down nav-link-arrow ms-1"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg" aria-labelledby="fileDropdown">
                            <div class="col-auto px-0">
                                <div class="list-group list-group-flush">
                                    <a @click.prevent='navigateTo("/f/grammar")' class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">                                           
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('Fix Grammar in File')  }}</span>                                            
                                        </div>
                                    </a>
                                    <a @click.prevent='navigateTo("/f/rephrase")' class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">                                           
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('AI Rephrase File')  }}</span>                                            
                                        </div>
                                    </a>
                                    <a @click.prevent='navigateTo("/f/deepl")' class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">                                           
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('Translate File')  }}</span>                                            
                                        </div>
                                    </a>
                                    <a @click.prevent='navigateTo("/overview?e=file")' class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">                                           
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('See More')  }}</span>                                            
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                    
                    <li v-show="gstate && gstate.user_info_json && gstate.user_info_json.loggedin" class="nav-item dropdown">
                        <a href="/fileviewer" class="nav-link" id="downloadDropdown">
                            {{$t("Your Files")}}
                        </a>
                    </li>
                    <li v-show="gstate && gstate.user_info_json && gstate.user_info_json.loggedin" class="nav-item dropdown">
                        <!-- v-if="gstate && gstate.user_info_json && gstate.user_info_json.loggedin" -->
                        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" id="accountDropdown" aria-expanded="false">
                            {{$t("Account")}}
                            <span class="fas fa-angle-down nav-link-arrow ms-1"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg" aria-labelledby="accountDropdown">
                            <div class="col-auto px-0">
                                <div class="list-group list-group-flush">
                                    <a href="/accountpage.html" class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">
                                        
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('Account')  }}</span>
                                            <span class="small">{{$t('Change Password; Apply Promo; Cancel Subscription') }}</span>
                                        </div>
                                    </a>
                                    <a href="/logout.html" class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">
                                        <div class="ms-4">
                                            <span class="d-block font-small fw-bold mb-0">{{ $t('Logout')  }}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li  v-show="gstate && gstate.user_info_json && gstate.user_info_json.loggedin"  class="nav-item dropdown">
                        <a @click.prevent="navigateTo('/addcredit')" class="nav-link" id="downloadDropdown">
                            {{$t("Add Credit")}}
                        </a>
                    </li>    

                    <li v-show="!(gstate && gstate.user_info_json && gstate.user_info_json.loggedin)" class="nav-item dropdown">
                        <a @click.prevent="navigateTo('/addcredit')" class="nav-link" id="downloadDropdown">
                            {{$t("Pricing")}}
                        </a>
                    </li>
                    <li v-show="!(gstate && gstate.user_info_json && gstate.user_info_json.loggedin)" class="nav-item dropdown">
                        <a href="/register" class="nav-link" id="register">
                            {{$t('Register')}}
                        </a>
                    </li>
                    <li v-show="!(gstate && gstate.user_info_json && gstate.user_info_json.loggedin)" class="nav-item dropdown">
                        <a href="/login" class="nav-link" id="login">
                            {{$t('Login')}}
                        </a>
                    </li>
                    <li class="nav-item dropdown">
                        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" id="localeDropdown" aria-expanded="false">
                            <i class="fas fa-language"></i>
                            <span class="fas fa-angle-down nav-link-arrow ms-1"></span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-lg" aria-labelledby="localeDropdown">
                            <div class="col-auto px-0">
                                <div class="list-group list-group-flush">
                                    <div v-for='language_config in [["en","fi-gb","English"], ["zh_TW", "fi-hk","繁體中文"], ["zh_CN", "fi-cn","简体中文"]]'>  
                                        <a  
                                        :key="language_config[0]"
                                        v-if="language_config[0] !== locale" 
                                        @click="set_language(language_config[0]);" 
                                        class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4" 
                                        :id="'locale_'+language_config[0]">
                                            <div class="ms-4" v-show="language_config[0] !== locale">
                                                <span class="d-block font-small fw-bold mb-0">
                                                    <span :class="['fi', 'me-1', language_config[1]]"></span>
                                                    {{language_config[2]}}
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="d-flex align-items-center my-3">
                                        <hr class="flex-grow-1">
                                        <span class="px-3 small text-muted">{{$t("Experimental Languages")}}</span>
                                        <hr class="flex-grow-1">
                                    </div>
                                    <div v-for="language_config in experimentalLanguages" :key="language">
                                        <a
                                        :key="language_config[0]"
                                        v-if="language_config[0] !== locale" 
                                        @click="set_language(language_config[0]);" 
                                        class="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4" 
                                        :id="'locale_'+language_config[0]">
                                            <div class="ms-4" v-show="language_config[0] !== locale">
                                                <span class="d-block font-small fw-bold mb-0">
                                                    <span :class="['fi', 'me-1', language_config[1]]"></span>
                                                    {{language_config[2]}}
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                  
              
            </div>
            <div class="d-flex align-items-center">
                <a v-show="false && !(gstate && gstate.user_info_json && gstate.user_info_json.loggedin)" @click="set_language('zh_TW');" class="btn btn-dribbble"><i class="fas fa-language"></i></a>
                <a v-show="gstate && gstate.user_info_json && gstate.user_info_json.loggedin" @click.prevent="navigateTo('/addcredit')" class="btn btn-dribbble" id="credit_amount_a">
                    <span id="credit_amount_span" display="inline-block"> ${{totalcredit}}</span>
                </a>
                <button class="navbar-toggler ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </div>
    </nav>
</header>
</template>

<script>
import { ref, onMounted, inject, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from './../stores/user';


export default {
  name: 'NavBar',
  setup() {
    const gstate = inject('gstate')
    const t = inject('t')
    const locale = inject('locale')
    const navigateTo = inject('navigateTo')
    const experimentalLanguages = [
        ['es', 'fi-es', 'Español'],
        ['fr', 'fi-fr', 'Français'],
        ['de', 'fi-de', 'Deutsch'],
        ['ja', 'fi-jp', '日本語'],
        ['ko', 'fi-kr', '한국어'],
        ['it', 'fi-it', 'Italiano'],
        ['el', 'fi-gr', 'Ελληνικά'],
        ['th', 'fi-th', 'ไทย']
    ]
    const totalcredit = computed(() => {
        let totalcredit = 0.0;
        ["creditone", "credittwo", "creditthree", "creditfour", "creditfive"].forEach((credit) => {
            if (gstate && gstate.user_info_json && gstate.user_info_json.hasOwnProperty(credit)) {
                totalcredit += parseFloat(gstate.user_info_json[credit]) || 0.0;
            }
        });
        //truncate to 1 digit
        totalcredit = Math.round(totalcredit * 10) / 10;
        return totalcredit;
    });
    function set_language(language) {
        setCookie('locale',language);
        gstate.counter += 1;
        locale.value = language;
        var xhttp = new XMLHttpRequest();
        xhttp.open("GET", `/_setlanguage/${language}`, true);
        xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhttp.send();
        if (document.querySelector("#navbar_global")) {
            document.querySelector("#navbar_global").classList.remove("show");
        }
    }

    onMounted(() => {
        // console.log('NavBar mounted')
        const breakpoints = {
            sm: 540,
            md: 720,
            lg: 960,
            xl: 1140
        };
        if (document.body.clientWidth > breakpoints.lg) {
            // Dropdown on hover for desktop . taken from pixel.js
            gstate.is_lg = true;
            var dropdownElementList = [].slice.call(document.querySelectorAll('.navbar .dropdown-toggle'))
            dropdownElementList.map(function (dropdownToggleEl) {
                var dropdown = new bootstrap.Dropdown(dropdownToggleEl);
                var dropdownMenu = document.querySelector('.dropdown-menu[aria-labelledby="' + dropdownToggleEl.getAttribute('id') + '"]');

                dropdownToggleEl.addEventListener('mouseover', function () {
                    dropdown.show();
                });
                dropdownToggleEl.addEventListener('mouseout', function () {
                    dropdown.hide();
                });

                dropdownMenu.addEventListener('mouseover', function () {
                    dropdown.show();
                });

                dropdownMenu.addEventListener('mouseout', function () {
                    dropdown.hide();
                });
                
            });
        } else {
            gstate.is_lg = false;
        }
    })

    return {
      totalcredit,
      set_language, navigateTo,
      gstate, t, locale, experimentalLanguages,
    }
  }
}

</script>